import React, { useEffect, useState } from 'react';
import './App.css';
import AppLayout from './layouts/app/appLayout/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import { login, selectTimeStamp, selectToken, selectTokenData, selectUserMode } from './redux/authSlice';
import CircularProgress from './modules/common/progress/Circular';
import AuthLayout from './layouts/auth/Auth.layout';
// import { withAITracking } from '@microsoft/applicationinsights-react-js';
// import { reactPlugin, appInsights } from './services/appInsights';
import appConfig from './configs/App.config';
import jwt_decode from 'jwt-decode';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './modules/home/homePage';
import Login from './modules/auth/login/Login';
import LandingPage from './modules/common/landingPage/LandingPage';
import Dashboard from './modules/app/dashboard/Dashboard';
import appRoutes from './configs/Routes.config';
import WildComponent from './modules/common/wildComponent/WildComponent';
import Profile from './modules/app/profile/Profile';
import ChangePwd from './modules/app/changePwd/ChangePwd';
import Logout from './modules/app/logout/Logout';
import Wrapper from './modules/common/wrapper/wrapper';

import AboutUs  from  './modules/app/infoPages/AboutUs'
import Glossary from './modules/app/infoPages/Glossary'
import ContactUs from './modules/app/infoPages/ContactUs'
import Tutorials from './modules/app/infoPages/Tutorials';
import AboutEjdap from './modules/app/additionalPages/AboutEjdap';
import Privacy from './modules/app/additionalPages/Privacy';
import Faqs from './modules/app/additionalPages/Faqs';
import GlossaryHelp from './modules/app/additionalPages/GlossaryHelp';

import { setZipCode } from './redux/appSlice';
import { selectLastEventTime, setLastEventTime } from './redux/userSlice';
import Cookies from './modules/app/additionalPages/Cookies';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const authTtoken = useSelector(selectToken);
  const userData$ = useSelector(selectTokenData);
  const userMode$ = useSelector(selectUserMode);
  // const keepMeLoggIn = useSelector(selectKeepMeLoggedIn);
  const [urlparts, setUrlParts] = useState(window.location.href.split('/'));
  const [activeChecker, setActiveChecker] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  setTimeout(() => {
      setIsLoading(false);
  }, 1000);

  useEffect(() => {
    const handleEvent = () => {
      const currentTimeStamp = new Date().getTime();
      dispatch(setLastEventTime(currentTimeStamp));
    };

    // Add event listeners for multiple events
    document.addEventListener('click', handleEvent);
    document.addEventListener('mousemove', handleEvent);
    document.addEventListener('keydown', handleEvent);
    document.addEventListener('scroll', handleEvent);
    document.addEventListener('wheel', handleEvent);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('click', handleEvent);
      document.removeEventListener('mousemove', handleEvent);
      document.removeEventListener('keydown', handleEvent);
      document.removeEventListener('scroll', handleEvent);
      document.removeEventListener('wheel', handleEvent);
    };
  }, []);
  
useEffect(() => {
  if(authTtoken === null){
    if (window.location.pathname === "/about") {
      navigate('/about');
    }else if (window.location.pathname === "/cookies-policy") {
      navigate('/cookies-policy');
    }else if(window.location.pathname === "/faqs"){
      navigate('/faqs')
    }else if(window.location.pathname === "/glossary"){
      navigate('/glossary')
    }else if(window.location.pathname === "/privacy-policy"){
      navigate('/privacy-policy')
    }else {
      navigate('/home');
    }
  }else{
    dispatch(setZipCode(userData$.USER_TYPE === "CBO" ? userData$.COMUNITY_ID : userData$.ZIPCODE));
  }
}, [authTtoken]);

  return (
  <>
    {isLoading ? (
      <div className="loading">
        <CircularProgress />
      </div>
    ) : (
    <>
    
      <div className="app-container">
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="/about" exact element={<AboutEjdap />} />
          <Route path="/cookies-policy" exact element={<Cookies />} />
          <Route path="/privacy-policy" exact element={<Privacy />} />
          <Route path="/faqs" exact element={<Faqs />} />
          <Route path="/glossary" exact element={<GlossaryHelp />} />
          <Route path="/home" exact element={<HomePage />} />
          <Route path="/app" exact element={<Wrapper />} >
            <Route index element={<Navigate to='dashboard' />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='glossary' element={<Glossary />} />
            <Route path='tutorials' element={<Tutorials />} />
            <Route path='contact' element={<ContactUs />} />
            <Route path='search/:zipcode' element={<Dashboard />} />
            {appRoutes.map((route, i1) => {
              if (route.type === 'treeMenu') {
                // if (route.path === 'saved-reports' && savedReports) {
                if (route.path === 'saved-reports') {
                  return (
                    <Route key={i1} path={route.path} >
                      <Route path=':zipcode' element={<Dashboard />} />
                      <Route path='*' element={<Navigate to='dashboard' replace={true} />} />
                    </Route>
                  );
                } else {
                  return (
                    <Route key={i1} path={route.path} element={route.component} >
                      <Route path='' exact element={<Navigate to={`/${route.path}/${(route.submenus.length ? route.submenus[0].path : '#')}`} replace={true} />} />
                      {route.submenus.map((submenu, i2) => {
                        return (
                          <Route key={i1 + '-' + i2} path={submenu.path} element={submenu.component} />
                        );
                      })}
                      <Route path='*' element={<Navigate to='dashboard' replace={true} />} />
                    </Route>
                  );
                }
              } else {
                return (
                  <Route key={i1} path={route.path} element={route.component} />
                );
              }
            })}
            <Route path='notifications' >
              <Route path='' element={<Navigate to="/notifications/all" replace={true} />} />
              <Route path=':id' element={<WildComponent />} />
            </Route>
            <Route path='profile/:user' element={<Profile />} />
            <Route path='changepwd' element={<ChangePwd />} />
            <Route path='logout' element={<Logout />} />
            <Route path="about" element={<WildComponent />} />
            <Route path="help" element={<WildComponent />} />
            {/* <Route element={<Navigate to='/dashboard' replace={true} />} /> */}
            <Route path="*" element={<Navigate to="dashboard" />} />
          </Route>
          <Route path="/auth" exact element={<AuthLayout />} >
            <Route path="" index element={<Navigate to="verify" replace={true} />} />
            <Route path="login" element={<Navigate to="verify" replace={true} />} />
            <Route path="verify" element={<Login />} />
            <Route path="*" element={<Navigate to="verify" />} />
          </Route>
          <Route path="*" exact element={<Navigate to="/" />} />
        </Routes>
      </div>

    </>
  )}
  </>
  );
}

// export default withAITracking(reactPlugin, App);

export default App;
